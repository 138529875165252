<template>
  <div id="journalism">
    <img
      src="../assets/image/bn5.jpg"
      alt=""
      class="logo"
    >
    <div class="journalism_box">
      <div class="journalism_router">
        <h1>
          新闻资讯 <span>Newx </span>
        </h1>
        <ul class="router_box">
          <li
            @click="mouSeover(0)"
            class="active"
          >公司新闻</li>
          <li @click="mouSeover(1)">行业新闻</li>
          <li @click="mouSeover(2)">媒体报到</li>
        </ul>
        <div class="contact">
          <h1>
            联系方式<span>Contact</span>
          </h1>
          <div class="contact_content">
            <ul>
              <li><label>公司名称</label>:贵州拓宇创业科技有限公司</li>
              <li><label>地址</label>:贵州省贵阳市国家高新技术产业开发区金阳科技产业园贵阳科技大厦A栋9楼</li>
              <li><label>服务热线</label>:0851-84875405</li>
              <li><label>邮箱</label>:tycycloud@163.com</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="journalism_content">
        <div class="content_router">
          <img
            src="../assets/image/right_wz.png"
            alt=""
          >
          您现在的位置：
          <el-breadcrumb separator-class="el-icon-arrow-right">

            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>新闻资讯</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="content">
          <div
            class="title"
            v-for="journalism in journalismData"
            :key="journalism.index"
          >

            <div class="img">
              <img
                :src="journalism.url"
                alt=""
              >
            </div>
            <div class="text">
              <h1 class="text_name">
                <a href="http://www.iotworld.com.cn/" target="_blank" >
                  {{journalism.name}}
                </a>
              </h1>
              <p class="text_content">
                {{journalism.text}}
              </p>
              <button class="text_btn">
                <a href="http://www.iotworld.com.cn/" target="_blank">
                  more>>
                </a>
              </button>
            </div>
          </div>
          <div class="content_total">
            <div class="total_name">
              共1页{{journalismData.length}}条记录
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页尾 -->
    <Footer />
  </div>
</template>



<script>
import $ from "jquery";
import Footer from '../components/Public/Footer.vue'

export default {
  name: "Journalism",
  components: {
    Footer
  },
  data() {
    return {
      journalismData: [
        {
          url: require('../assets/image/lp1.jpg'),
          name: "传滴滴即将接入ofo，共享单车大战格局或.....",
          text: "36氪从ofo内部获得消息，可能在今年4月份，ofo将会接入到滴滴出行的App上，届时用户可以直接在滴滴上面使用ofo的......."

        },
        {
          url: require('../assets/image/lp2.jpg'),
          name: "点击在线求助，应答的却都是机器人，这样真....",
          text: "人工智能技术的发展正在不断地改变着人们的生活与工作方式，它在一定程度上推动了社会的发展与进步，这一点是毋庸置疑的。但人工......."

        },
        {
          url: require('../assets/image/lp3.jpg'),
          name: "美业信息化规模将现，门庭管店为他们提供了....",
          text: "数据显示，2015年，我国服务业同比增长8.3%，分别高于国内生产总值和第二产业增加值增速1.4和2.3个百分点，占国内......."

        },
        {
          url: require('../assets/image/lp1.png'),
          name: "无人便利店风口正当时，“便利家”获联创永....",
          text: "36氪获悉，无人便利店“便利家”已获得数百万人民币天使轮投资，资方为联创永宣冯涛。无人便利店风口正当时，缤果盒子、F5未来......."

        },
      ]
    }
  },
  mounted() {
  },
  methods: {
    mouSeover(idx) {
      $(`.router_box li:eq(${idx})`).addClass("active");
      $(`.router_box li:eq(${idx})`).siblings().removeClass("active");
      this.activeLeft = `-${idx * 100}%`;
    },
  }
}
</script>



<style lang="scss">
#journalism {
  padding-top: 60px;
  height: auto;
  .logo {
    width: 100vw;
    height: auto;
  }
  .journalism_box {
    width: 1160px;
    height: auto;
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
    background: url("../assets/image/portal_ft.jpg") center bottom no-repeat;
    .journalism_router {
      width: 300px;
      h1 {
        line-height: 35px;
        background: #00AEEF;
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        padding: 15px 0 0 15px;
        height: 40px;
        span {
          font-size: 12px;
          font-weight: normal;
        }
      }
      ul {
        background-color: #fff8fb;
        li {
          padding: 10px 10px;
          border: 1px solid #eee;
          font-size: 16px;
          color: #666;
          font-weight: bold;
          cursor: pointer;
          &.active {
            color: #00AEEF;
            text-decoration: none;
            background: url("../assets/image/left_sel_03.gif") left center
              no-repeat;
          }
          &:hover {
            color: #00AEEF;
          }
        }
      }
      .contact {
        margin-top: 20px;
        border: 1px solid #eee;
        border-top: 0;
        h1 {
          padding: 0 0 0 15px;
        }
        ul {
          padding-top: 70px;
          background: #f6f6f6 url("../assets/image/contact.jpg") left top
            no-repeat;
          li {
            border: 0;
            height: auto;
            line-height: 35px;
            label {
              display: inline-block;
              width: 80px;
              height: 30px;
              line-height: 30px;
              vertical-align: middle;
              text-align: justify;
              text-align-last: justify;
            }
          }
        }
      }
    }
    .journalism_content {
      width: 800px;
      height: auto;
      padding-top: 30px;
      .content_router {
        display: flex;
        flex-flow: row nowrap;
        justify-content: start;
        align-items: center;
        color: #999;
        font-size: 12px;
        height: 30px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e6e6e6;
        img {
          margin-right: 5px;
        }
        .el-breadcrumb {
          font-size: 12px;
          .el-breadcrumb__inner {
            color: #999;
            &.is-link {
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .content {
        .title {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          padding-bottom: 10px;
          border-bottom: 1px dashed #e6e6e6;
          margin-bottom: 20px;
          .img {
            width: 30%;
            img {
              width: 100%;
              height: auto;
            }
          }
          .text {
            width: 60%;
            height: auto;
            &_name {
              a {
                color: #00AEEF;
                font-weight: bold;
                white-space: nowrap;
                line-height: 44px;
                font-size: 18px;
              }
            }
            &_content {
              line-height: 24px;
              font-size: 12px;
              height: 72px;
              display: block;
              overflow: hidden;
              color: #666666;
            }
            &_btn {
              outline: none;
              margin-top: 15px;
              background-color: transparent;
              border: 0;
              a {
                color: #666666;
              }
              &:hover {
                a {
                  text-decoration: underline;
                }
              }
            }
          }
          &:hover {
            .text {
              &_name {
                a {
                  color: #666;
                }
              }
            }
          }
        }
        .content_total {
          width: 100%;
          height: 100px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          .total_name {
            border: 1px solid #ccc;
            padding: 2px 9px;
            margin: 0 3px;
            line-height: 20px;
            background: #fff;
            color: #999;
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (max-width: 1160px) {
  #journalism {
    padding-top: 60px;
    height: auto;
    .logo {
      width: 100vw;
      height: auto;
    }
    .journalism_box {
      width: 90vw;
      height: auto;
      margin: auto;
      margin-top: 50px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      padding-bottom: 10px;
      background: url("../assets/image/portal_ft.jpg") center bottom no-repeat;
      .journalism_router {
        width: 20vw;
        h1 {
          line-height: 35px;
          background: #00AEEF;
          color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          padding: 15px 0 0 15px;
          height: 40px;
          span {
            font-size: 12px;
            font-weight: normal;
          }
        }
        ul {
          background-color: #fff8fb;
          li {
            padding: 10px 10px;
            border: 1px solid #eee;
            &.active {
              color: #00AEEF;
              text-decoration: none;
              background: url("../assets/image/left_sel_03.gif") left center
                no-repeat;
            }
            &:hover {
              color: #00AEEF;
            }
          }
        }
        .contact {
          margin-top: 20px;
          border: 1px solid #eee;
          border-top: 0;
          h1 {
            padding: 0 0 0 15px;
          }
          ul {
            padding-top: 70px;
            background: #f6f6f6 url("../assets/image/contact.jpg") left top
              no-repeat;
            li {
              border: 0;
            }
          }
        }
      }
      .journalism_content {
        width: 65vw;
        height: auto;
        padding-top: 30px;
        .content_router {
          display: flex;
          flex-flow: row nowrap;
          justify-content: start;
          align-items: center;
          color: #999;
          font-size: 12px;
          height: 30px;
          margin-bottom: 20px;
          border-bottom: 1px solid #e6e6e6;
          img {
            margin-right: 5px;
          }
          .el-breadcrumb {
            font-size: 12px;
            .el-breadcrumb__inner {
              color: #999;
              &.is-link {
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .content {
          .title {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            padding-bottom: 10px;
            border-bottom: 1px dashed #e6e6e6;
            margin-bottom: 20px;
            .img {
              width: 30%;
              img {
                width: 100%;
                height: auto;
              }
            }
            .text {
              width: 60%;
              height: auto;
              &_name {
                color: #00AEEF;
                font-weight: bold;
                line-height: 44px;
                font-size: 18px;
                cursor: pointer;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              &_content {
                line-height: 24px;
                font-size: 12px;
                height: 72px;
                display: block;
                overflow: hidden;
                color: #666666;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
              }
              &_btn {
                margin-top: 15px;
                background-color: transparent;
                border: 0;
                color: #666666;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            &:hover {
              .text {
                &_name {
                  color: #666;
                }
              }
            }
          }
          .content_total {
            width: 100%;
            height: 100px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            .total_name {
              border: 1px solid #ccc;
              padding: 2px 9px;
              margin: 0 3px;
              line-height: 20px;
              background: #fff;
              color: #999;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 850px) {
  #journalism {
    padding-top: 60px;
    height: auto;
    .logo {
      width: 100vw;
      height: auto;
    }
    .journalism_box {
      width: 90vw;
      height: auto;
      margin: auto;
      margin-top: 50px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding-bottom: 10px;
      background: url("../assets/image/portal_ft.jpg") center bottom no-repeat;
      .journalism_router {
        width: 100vw;
        h1 {
          line-height: 35px;
          background: #00AEEF;
          color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          padding: 15px 0 0 15px;
          height: 40px;
          display: none;
          span {
            font-size: 12px;
            font-weight: normal;
          }
        }
        ul {
          background-color: transparent;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          li {
            padding: 10px 10px;
            border: 0;
            &.active {
              color: #00AEEF;
              text-decoration: none;
              background: transparent;
            }
            &:hover {
              color: #00AEEF;
            }
          }
        }
        .contact {
          margin-top: 20px;
          border: 1px solid #eee;
          border-top: 0;
          display: none;
          h1 {
            padding: 0 0 0 15px;
          }
          ul {
            padding-top: 70px;
            background: #f6f6f6 url("../assets/image/contact.jpg") left top
              no-repeat;
            li {
              border: 0;
            }
          }
        }
      }
      .journalism_content {
        width: 100vw;
        height: auto;
        padding-top: 30px;
        .content_router {
          display: none;
          color: #999;
          font-size: 12px;
          height: 30px;
          margin-bottom: 20px;
          border-bottom: 1px solid #e6e6e6;
          img {
            margin-right: 5px;
          }
          .el-breadcrumb {
            font-size: 12px;
            .el-breadcrumb__inner {
              color: #999;
              &.is-link {
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .content {
          .title {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            padding-bottom: 10px;
            border-bottom: 1px dashed #e6e6e6;
            margin-bottom: 20px;
            .img {
              width: 30%;
              img {
                width: 100%;
                height: auto;
              }
            }
            .text {
              width: 60%;
              height: auto;
              &_name {
                color: #00AEEF;
                font-weight: bold;
                line-height: 44px;
                font-size: 18px;
                cursor: pointer;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              &_content {
                line-height: 24px;
                font-size: 12px;
                height: 72px;
                display: block;
                overflow: hidden;
                color: #666666;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
              }
              &_btn {
                margin-top: 15px;
                background-color: transparent;
                border: 0;
                color: #666666;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            &:hover {
              .text {
                &_name {
                  color: #666;
                }
              }
            }
          }
          .content_total {
            width: 100%;
            height: 100px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            .total_name {
              border: 1px solid #ccc;
              padding: 2px 9px;
              margin: 0 3px;
              line-height: 20px;
              background: #fff;
              color: #999;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 425px) {
  #journalism {
    padding-top: 60px;
    height: auto;
    .logo {
      width: 100vw;
      height: auto;
    }
    .journalism_box {
      width: 90vw;
      height: auto;
      margin: auto;
      margin-top: 50px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding-bottom: 10px;
      background: url("../assets/image/portal_ft.jpg") center bottom no-repeat;
      .journalism_router {
        width: 100vw;
        h1 {
          line-height: 35px;
          background: #00AEEF;
          color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          padding: 15px 0 0 15px;
          height: 40px;
          display: none;
          span {
            font-size: 12px;
            font-weight: normal;
          }
        }
        ul {
          background-color: transparent;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          li {
            padding: 10px 10px;
            border: 0;
            &.active {
              color: #00AEEF;
              text-decoration: none;
              background: transparent;
            }
            &:hover {
              color: #00AEEF;
            }
          }
        }
        .contact {
          margin-top: 20px;
          border: 1px solid #eee;
          border-top: 0;
          display: none;
          h1 {
            padding: 0 0 0 15px;
          }
          ul {
            padding-top: 70px;
            background: #f6f6f6 url("../assets/image/contact.jpg") left top
              no-repeat;
            li {
              border: 0;
            }
          }
        }
      }
      .journalism_content {
        width: 100vw;
        height: auto;
        padding-top: 30px;
        .content_router {
          display: none;
          color: #999;
          font-size: 12px;
          height: 30px;
          margin-bottom: 20px;
          border-bottom: 1px solid #e6e6e6;
          img {
            margin-right: 5px;
          }
          .el-breadcrumb {
            font-size: 12px;
            .el-breadcrumb__inner {
              color: #999;
              &.is-link {
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        .content {
          .title {
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-between;
            padding-bottom: 10px;
            border-bottom: 1px dashed #e6e6e6;
            margin-bottom: 20px;
            .img {
              width: 100%;
              img {
                width: 100%;
                height: auto;
              }
            }
            .text {
              width: 100%;
              height: auto;
              &_name {
                color: #00AEEF;
                font-weight: bold;
                line-height: 44px;
                font-size: 18px;
                cursor: pointer;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
              }
              &_content {
                line-height: 24px;
                font-size: 12px;
                height: 72px;
                display: block;
                overflow: hidden;
                color: #666666;
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
              }
              &_btn {
                margin-top: 15px;
                background-color: transparent;
                border: 0;
                color: #666666;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            &:hover {
              .text {
                &_name {
                  color: #666;
                }
              }
            }
          }
          .content_total {
            width: 100%;
            height: 100px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            .total_name {
              border: 1px solid #ccc;
              padding: 2px 9px;
              margin: 0 3px;
              line-height: 20px;
              background: #fff;
              color: #999;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>